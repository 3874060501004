import {
  ExtractAtomicOperationFromDefinition,
  createAtomicOperationDefinition,
} from "../atomic-operations/AtomicOperationDefinition";
import { DateTimeString } from "../dateTypes";
import { CellExecutionState, CellRunMode } from "../enums";
import { AppSessionCellId, CellId } from "../idTypeBrands";
import { SemanticCap } from "../semanticCaps.js";

const UPDATE_CELL_STATUS_TYPE = "UPDATE_CELL_STATUS" as const;

export const UPDATE_CELL_STATUS = createAtomicOperationDefinition({
  type: UPDATE_CELL_STATUS_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_APP_SESSION,
  },
  writeAuth: {
    kind: "hasSemanticCapOnIdArg",
    cap: SemanticCap.EDIT_APP_SESSION,
    idArg: "appSessionCellId",
    idType: "AppSessionCell",
  },
  alwaysSkipUndoBuffer: true,
  logSafe: ["appSessionCellId", "state", "traceId", "executeTrace"],
  conflictId: (op) =>
    `${UPDATE_CELL_STATUS_TYPE}-${op.payload.appSessionCellId}`,
  creationId: (op) =>
    `${CREATE_APP_SESSION_CELL.type}-${op.payload.appSessionCellId}`,
  create: (args: {
    appSessionCellId: AppSessionCellId;
    state: CellExecutionState;
    traceId?: string;
    executeTrace?: boolean;
  }) => ({
    type: UPDATE_CELL_STATUS_TYPE,
    payload: {
      ...args,
    },
  }),
});

export type UPDATE_CELL_STATUS = ExtractAtomicOperationFromDefinition<
  typeof UPDATE_CELL_STATUS
>;

const CREATE_APP_SESSION_CELL_TYPE = "CREATE_APP_SESSION_CELL" as const;

export const CREATE_APP_SESSION_CELL = createAtomicOperationDefinition({
  type: CREATE_APP_SESSION_CELL_TYPE,
  readAuth: {
    kind: "and",
    and: [
      {
        kind: "hasSemanticCap",
        cap: SemanticCap.VIEW_APP_SESSION,
      },
      {
        kind: "hasSomeOfSemanticCapsOnIdArg",
        someOfCaps: [
          SemanticCap.VIEW_PROJECT_CONTENTS_FOR_APP,
          SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
        ],
        idArg: "cellId",
        idType: "Cell",
      },
    ],
  },
  writeAuth: {
    kind: "and",
    and: [
      {
        kind: "hasSemanticCap",
        cap: SemanticCap.EDIT_APP_SESSION,
      },
      {
        kind: "hasSomeOfSemanticCapsOnIdArg",
        someOfCaps: [
          SemanticCap.VIEW_PROJECT_CONTENTS_FOR_APP,
          SemanticCap.VIEW_PROJECT_CONTENTS_FOR_LOGIC,
        ],
        idArg: "cellId",
        idType: "Cell",
      },
      {
        kind: "idArgDoesNotExist",
        idArg: "appSessionCellId",
        idType: "AppSessionCell",
      },
    ],
  },
  alwaysSkipUndoBuffer: true,
  logSafe: ["appSessionCellId", "cellId"],
  conflictId: (op) =>
    `${CREATE_APP_SESSION_CELL_TYPE}-${op.payload.appSessionCellId}`,
  create: (args: { appSessionCellId: AppSessionCellId; cellId: CellId }) => ({
    type: CREATE_APP_SESSION_CELL_TYPE,
    payload: {
      ...args,
    },
  }),
});

export class AppSessionCellAlreadyExistsError extends Error {
  constructor(existingAppSessionCellId: AppSessionCellId) {
    super(
      `Tried to create an appSessionCell that already existed, existing id: ${existingAppSessionCellId}`,
    );
  }
}

export type CREATE_APP_SESSION_CELL = ExtractAtomicOperationFromDefinition<
  typeof CREATE_APP_SESSION_CELL
>;

const RESTORE_APP_SESSION_CELL_TYPE = "RESTORE_APP_SESSION_CELL" as const;
const DELETE_APP_SESSION_CELL_TYPE = "DELETE_APP_SESSION_CELL" as const;

export const RESTORE_APP_SESSION_CELL = createAtomicOperationDefinition({
  type: RESTORE_APP_SESSION_CELL_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_APP_SESSION,
  },
  writeAuth: {
    kind: "hasSemanticCapOnIdArg",
    cap: SemanticCap.EDIT_APP_SESSION,
    idArg: "appSessionCellId",
    idType: "AppSessionCell",
  },
  logSafe: ["appSessionCellId"],
  alwaysSkipUndoBuffer: true,
  conflictId: (op) =>
    `${DELETE_APP_SESSION_CELL_TYPE}-${RESTORE_APP_SESSION_CELL_TYPE}-${op.payload.appSessionCellId}`,
  creationId: (op) =>
    `${CREATE_APP_SESSION_CELL.type}-${op.payload.appSessionCellId}`,
  create: ({
    appSessionCellId,
    appSessionCellRestoreInfo = null,
  }: {
    appSessionCellId: AppSessionCellId;
    // the server adds this optional argument
    // in case a new client joins that
    // can't restore using only the appSessionCellId
    appSessionCellRestoreInfo?: {
      cellId: CellId;
      createdDate: DateTimeString;
      updatedDate: DateTimeString;
      revision: number;
      state: CellExecutionState;
    } | null;
  }) => ({
    type: RESTORE_APP_SESSION_CELL_TYPE,
    payload: {
      appSessionCellId,
      appSessionCellRestoreInfo,
    },
  }),
});

export type RESTORE_APP_SESSION_CELL = ExtractAtomicOperationFromDefinition<
  typeof RESTORE_APP_SESSION_CELL
>;

export const DELETE_APP_SESSION_CELL = createAtomicOperationDefinition({
  type: DELETE_APP_SESSION_CELL_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_APP_SESSION,
  },
  writeAuth: {
    kind: "hasSemanticCapOnIdArg",
    cap: SemanticCap.EDIT_APP_SESSION,
    idArg: "appSessionCellId",
    idType: "AppSessionCell",
  },
  alwaysSkipUndoBuffer: true,
  logSafe: ["appSessionCellId"],
  conflictId: (op) =>
    `${DELETE_APP_SESSION_CELL_TYPE}-${RESTORE_APP_SESSION_CELL_TYPE}-${op.payload.appSessionCellId}`,
  creationId: (op) =>
    `${CREATE_APP_SESSION_CELL.type}-${op.payload.appSessionCellId}`,
  create: (args: { appSessionCellId: AppSessionCellId }) => ({
    type: DELETE_APP_SESSION_CELL_TYPE,
    payload: {
      ...args,
    },
  }),
});

export type DELETE_APP_SESSION_CELL = ExtractAtomicOperationFromDefinition<
  typeof DELETE_APP_SESSION_CELL
>;

const RUN_CELL_TYPE = "RUN_CELL" as const;

export const RUN_CELL = createAtomicOperationDefinition({
  type: RUN_CELL_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_APP_SESSION,
  },
  writeAuth: {
    kind: "hasSemanticCapOnIdArg",
    cap: SemanticCap.EDIT_APP_SESSION,
    idArg: "appSessionCellId",
    idType: "AppSessionCell",
  },
  logSafe: [
    "appSessionCellId",
    "forceOverwriteCache",
    "isPendingMagicDiffRun",
    "executeTrace",
    "forLogicView",
    "cellRunMode",
  ],
  alwaysSkipUndoBuffer: true,
  conflictId: (op) =>
    `${UPDATE_CELL_STATUS.type}-${op.payload.appSessionCellId}`,
  creationId: (op) =>
    `${CREATE_APP_SESSION_CELL.type}-${op.payload.appSessionCellId}`,
  create: (args: {
    appSessionCellId: AppSessionCellId;
    cellRunMode?: CellRunMode;
    source?: string;
    forceOverwriteCache?: boolean;
    isPendingMagicDiffRun?: boolean;
    executeTrace?: boolean;
    forLogicView?: boolean;
    maybeSkipSql?: boolean;
  }) => ({
    type: RUN_CELL_TYPE,
    payload: {
      ...args,
    },
  }),
});

export type RUN_CELL = ExtractAtomicOperationFromDefinition<typeof RUN_CELL>;

const INTERRUPT_CELL_TYPE = "INTERRUPT_CELL" as const;

export const INTERRUPT_CELL = createAtomicOperationDefinition({
  type: INTERRUPT_CELL_TYPE,
  readAuth: {
    kind: "hasSemanticCap",
    cap: SemanticCap.VIEW_APP_SESSION,
  },
  writeAuth: {
    kind: "hasSemanticCapOnIdArg",
    cap: SemanticCap.EDIT_APP_SESSION,
    idArg: "appSessionCellId",
    idType: "AppSessionCell",
  },
  logSafe: ["appSessionCellId"],
  alwaysSkipUndoBuffer: true,
  conflictId: (op) =>
    `${UPDATE_CELL_STATUS.type}-${op.payload.appSessionCellId}`,
  creationId: (op) =>
    `${CREATE_APP_SESSION_CELL.type}-${op.payload.appSessionCellId}`,
  create: (args: { appSessionCellId: AppSessionCellId }) => ({
    type: INTERRUPT_CELL_TYPE,
    payload: {
      ...args,
    },
  }),
});
export type INTERRUPT_CELL = ExtractAtomicOperationFromDefinition<
  typeof INTERRUPT_CELL
>;
