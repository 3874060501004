import { EnumValues } from "./enums";

export const ThemeType = {
  LIGHT: "LIGHT",
  DARK: "DARK",
} as const;

export type ThemeType = EnumValues<typeof ThemeType>;
export const AppTheme = {
  ...ThemeType,
  SYS_PREF: "SYS_PREF",
} as const;
export type AppTheme = EnumValues<typeof AppTheme>;
