/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthRule } from "../AuthRule";

/**
 * The most basic 'atom' of multiplayer, this is sent between
 * the server and clients for mutating the model.
 *
 * The type `PAYLOAD` must be serializable.
 */
export interface AtomicOperation<
  OPERATION_TYPE extends string,
  PAYLOAD extends Record<string, any>,
> {
  /**
   * Discriminant that makes it easy to determine the type of this operation
   */
  type: OPERATION_TYPE;
  /**
   * The actual contents of the operation
   */
  payload: PAYLOAD;
  /**
   * This key is banned, as it should be put on the AtomicOperationDefinition instead
   * @deprecated @see AtomicOperationDefinition["conflictId"]
   */
  conflictId?: never;
  /**
   * This key is banned, as it should be put on the AtomicOperationDefinition instead
   * @deprecated @see AtomicOperationDefinition["creationId"]
   */
  creationId?: never;
  /**
   * This key is banned, as it should be put on the AtomicOperationDefinition instead
   * @deprecated @see AtomicOperationDefinition["alwaysSkipUndoBuffer"]
   */
  alwaysSkipUndoBuffer?: never;
}

export type ConstantOrPerOp<
  T,
  CREATE extends (...args: any[]) => GenericAtomicOperation,
> =
  | T
  | ((
      op: AtomicOperation<
        ReturnType<CREATE>["type"],
        ReturnType<CREATE>["payload"]
      >,
    ) => T);

export type OpForIds<
  T,
  CREATE extends (...args: any[]) => GenericAtomicOperation,
> = (
  op: AtomicOperation<
    ReturnType<CREATE>["type"],
    ReturnType<CREATE>["payload"]
  >,
) => T;

/**
 * Meta data about an operation type used to properly handle
 * it both on the server and the client.
 */
export type AtomicOperationDefinition<
  CREATE extends (...args: any[]) => GenericAtomicOperation,
  EXTRA_OPTIONS = unknown,
> = EXTRA_OPTIONS & {
  type: ReturnType<CREATE>["type"];
  /**
   * Auth rule for enforcing whether or not a client can view a specific operation.
   *
   * The "arguments" this auth rule checks is the type `PAYLOAD` *NOT* the type `CREATE_ARGS`.
   * This is beacuse `create()` is called client side, so those arguments are not available to
   * the server to check.
   */
  readAuth: AuthRule;
  /**
   * Auth rule for enforcing whether or not a client can dispatch a specific operation.
   *
   * The "arguments" this auth rule checks is the type `PAYLOAD` *NOT* the type `CREATE_ARGS`.
   * This is because `create()` is called client side, so those arguments are not available to
   * the server to check.
   */
  writeAuth: AuthRule;
  /**
   * Which keys of type `PAYLOAD` whose values are safe to log.
   *
   * Can also be a function that takes an operation and produces a record of log safe values.
   */
  logSafe:
    | readonly (keyof ReturnType<CREATE>["payload"])[]
    | ((args: {
        operation: AtomicOperation<
          ReturnType<CREATE>["type"],
          ReturnType<CREATE>["payload"]
        >;
        dangerouslyUnsafe: boolean;
      }) => Record<string, unknown>);
  /**
   * Handy dandy function for actually constructing operations of this type.
   */
  create: CREATE;
  /**
   * Function for determining the conflict id of a specific operation.
   *
   * Any operations that share a conflict id are assumed
   * to completely overwrite each other. As such, if there are two actions
   * that would undo each other, for example, DELETE and RESTORE, they should
   * share a conflict id.
   *
   * For example, if operations A and B shared a conflict id:
   * - If A is applied first, B should completely overwrite any changes from it
   * - If B is applied first, A should completely overwrite any changes from it
   *
   * If thats not the case they should be split into two seperate operations.
   */
  conflictId: OpForIds<string, CREATE>;
  /**
   * Function for determining the creation id of a specific operation.
   *
   * If an operation has a creation id, it means that
   * it should not be sent to the remote until an operation
   * with the specified creation ID has been acked
   */
  creationId?: OpForIds<string, CREATE>;
  /**
   * Whether or not operations of this type are ever added to the undo buffer.
   * If `true`, operations of this type cannot be undone.
   *
   * However, we still need to implement undo/redo client side
   * for rollbacks.
   *
   * @default false
   */
  alwaysSkipUndoBuffer?: ConstantOrPerOp<boolean, CREATE>;
};

export type ExtractAtomicOperationFromDefinition<OPERATION_DEF> =
  OPERATION_DEF extends AtomicOperationDefinition<infer CREATE>
    ? AtomicOperation<ReturnType<CREATE>["type"], ReturnType<CREATE>["payload"]>
    : never;

/**
 * Helper function that makes it easier to make sure given operation
 * definition is well typed.
 */
export const createAtomicOperationDefinition = <
  CREATE extends (...args: any[]) => GenericAtomicOperation,
>(
  baseDef: AtomicOperationDefinition<CREATE>,
): AtomicOperationDefinition<CREATE> => baseDef;

/**
 * Wrapper for an atomic operation being sent to the server
 */
export interface AtomicOperationRequest<
  OPERATION extends AtomicOperation<string, any> = AtomicOperation<
    string,
    Record<string, Record<string, any>>
  >,
> {
  /**
   * Unique indentifier of a multiplayer client.
   *
   * Used for figuring out if an operation originated from
   * a given browser tab etc, NOT a real user / api client id
   */
  mpClientId: string;
  operation: OPERATION;
}

/**
 * Singleton multiplayer client id for any operations created by the server.
 */
export const SERVER_CLIENT_ID = "server";

export type GenericAtomicOperation = AtomicOperation<
  string,
  Record<string, any>
>;

/**
 * Utility type for representing any batched set of objects in AO.
 * Atomic Operations are often represented as a 2d array that shouldn't be mutated
 * Each nested array represents a set of operations that should also be processed as a batch.
 */
export type ReadonlyBatch<O> = ReadonlyArray<ReadonlyArray<Readonly<O>>>;

/**
 * Utility type for batched AO responses, received by the client.
 */
export type BatchedAOResponses<OPERATION extends GenericAtomicOperation> =
  ReadonlyBatch<AtomicOperationResponse<OPERATION>>;

/**
 * Wrapper for an atomic operation being recieved by a client
 */
export interface AtomicOperationResponse<
  OPERATION extends AtomicOperation<
    string,
    Record<string, any>
  > = GenericAtomicOperation,
> {
  mpClientId: string;
  operation: OPERATION;
  revision: number;
}

export type AtomicOperationDefinitionMap<
  OPERATION extends GenericAtomicOperation,
  MODEL_PAYLOAD_ID_ARG extends string,
  EXTRA_OPTIONS = unknown,
> = {
  [OP in OPERATION as OP["type"]]: AtomicOperationDefinition<
    (
      ...args: any[]
    ) => AtomicOperation<OP["type"], Omit<OP["payload"], MODEL_PAYLOAD_ID_ARG>>,
    EXTRA_OPTIONS
  >;
};
