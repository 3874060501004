import { Number, Record, Static, String, Union } from "runtypes";

import { OrgRoleLiteral } from "./enums";
import { OrgId, UserId } from "./idTypeBrands";

export const AuthenticatedUserOrgDetails = Record({
  userId: UserId,
  id: OrgId,
  displayName: String,
  orgRole: OrgRoleLiteral,
  email: String,
  userCount: Number.optional(),
});

export type AuthenticatedUserOrgDetails = Static<
  typeof AuthenticatedUserOrgDetails
>;

export const UnAuthenticatedUserOrgDetails = Record({
  id: OrgId,
  displayName: String,
  email: String,
});

export type UnAuthenticatedUserOrgDetails = Static<
  typeof UnAuthenticatedUserOrgDetails
>;

export const UserOrgDetails = Union(
  AuthenticatedUserOrgDetails,
  UnAuthenticatedUserOrgDetails,
);

export type UserOrgDetails = Static<typeof UserOrgDetails>;
