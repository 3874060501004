// If what you need is to assert that it's non-null and get the value,
// use `nullthrows`.
export function assertIsDefined<T>(
  val: T,
  message?: string,
): asserts val is NonNullable<T> {
  if (val == null) {
    throw new Error(
      message ?? `Expected 'val' to be defined, but received ${val}`,
    );
  }
}

export function nullthrows<T>(
  v: T | null | undefined,
  message: string | undefined = "Expected value to not be null or undefined.",
): T {
  if (v == null) {
    throw new Error(message);
  }
  return v;
}

export function assertGuard(
  condition: unknown,
  msg?: string,
): asserts condition {
  if (!condition) {
    throw new Error(msg);
  }
}
